@import url(https://fonts.googleapis.com/css?family=Consolas);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300&display=swap);
.nav__list {
  margin-right: 1.5em;
  display: flex;
}
.nav__list-item {
  margin-left: 1.5em;
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .header {
    height: 6em;
  }
}

/*
.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 700px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}
*/

.grid-container {
  display: grid;
  grid-template-columns: 75% 10%;
  grid-gap: 5%;
  line-height: 150%;
}

.project {
  padding: 5% 10% 5% 10%;
  margin: 0 auto;
  text-align: left;
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
}

.project:hover {
  -webkit-transform: translateY(-7px);
          transform: translateY(-7px);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
  justify-content: center;
}

.projects__grid {
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  grid-gap: 2em;
}

.history-tl-container {
}

.history-tl-container ul.tl li {
  max-width: 90%;
  padding: 10px 10px 50px 30px;
  list-style: square;
}

ul.tl li .item-title {
  font-size: 1.4rem;
  font-weight: bold;
}

ul.tl li .item-detail {
  max-width: 80%;
}

.skills__list {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  background-color: transparent;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}

.contact {
  flex-direction: column;
}

.current__desc {
  font-size: 1rem;
  max-width: 80%;
  margin-top: 5%;
  line-height: 150%;
}

.footer {
  padding: 3em 0;
  margin-top: 4em;
  text-align: center;
}

.footer__link {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--clr-fg);
}

@media (max-width: 600px) {
  .footer {
    padding: 2em;
    margin-top: 3em;
  }
}

.app {
  font-family: 'Consolas', monospace;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
  line-height: 150%;
}

.light {
  --clr-bg: #fff;
  --clr-bg-alt: #fff;
  --clr-fg: #000;
  --clr-fg-alt: #000;
  --clr-primary: #458588;
  --clr-secondary: #458588;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-secondary);
  position: relative;
  text-decoration: underline;
  display: inline;
}

.link:hover {
  color: var(--clr-secondary);
  background-color: var(--clr-primary);
  color: var(--clr-bg);
  text-decoration: none;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-primary);
  font-weight: 800;
}

.link--nav--pressed {
  color: var(--clr-secondary);
  background-color: var(--clr-primary);
  color: var(--clr-bg);
  text-decoration: none;
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}

.nav__list {
  margin-right: 1.5em;
  display: flex;
}

.nav__list-item {
  margin-left: 1.5em;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

